<template>
  <b-card>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)">

        <div class="d-flex">
          <feather-icon icon="CheckSquareIcon" size="16"/>
          <h5 class="mb-0 mt-0 ml-50">Genel Bilgiler</h5>
        </div>

        <b-row class="mt-1">

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="İsim" rules="required">
              <b-form-group label="* İsim">
                <b-form-input trim placeholder="İsim" v-model="dataInfo.name" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Pozisyon" rules="required">
              <b-form-group label="* Pozisyon" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.positionId" :options="positionOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Durum" rules="required">
              <b-form-group label="* Durum" label-for="isActive" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6" lg="6">
            <validation-provider #default="validationContext" name="Görev Açılırken Gösterilsin" rules="required">
              <b-form-group label="* Görev Açılırken Gösterilsin" :state="getValidationState(validationContext)">
                <v-select v-model="dataInfo.showOnTask" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="12" lg="12">
            <validation-provider #default="validationContext" name="Açıklama">
              <b-form-group label="Açıklama">
                <b-form-textarea v-model="dataInfo.description" rows="2" placeholder="Açıklama..."/>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <div class="d-flex justify-content-between align-items-center border-bottom border-top pt-1 pr-1 pb-1">
          <div class="d-flex">
            <feather-icon icon="CheckSquareIcon" size="16"/>
            <h5 class="mb-0 mt-0 ml-50">İş Tanımları</h5>
          </div>
          <b-button variant="primary" @click="taskDefinitionNewModal" size="sm">
            <feather-icon icon="PlusSquareIcon" size="16"/>
            <span class="align-middle" role="button"> Ekle</span>
          </b-button>
        </div>

        <app-collapse accordion type="shadow" class="mt-1" v-if="dataInfo.taskDefinitions.length > 0">

          <app-collapse-item class="bg-light-primary" :title="taskDefinition.name" :sort-number="(index+1)" :is-visible="!taskDefinition.isDeleted" v-for="(taskDefinition, index) in dataInfo.taskDefinitions" :key="index" v-if="!taskDefinition.isDeleted">

            <div class="d-flex justify-content-between">
              <div>
                <b-button variant="primary" class="btn-icon mt-2" @click="taskDefinitionProcessNewModal(taskDefinition)">
                  <feather-icon icon="PlusSquareIcon"/>
                  Yeni Adım
                </b-button>
                <b-button variant="warning" class="btn-icon ml-1 mt-2" @click="taskDefinitionEdit(taskDefinition)">
                  <feather-icon icon="EditIcon"/>
                  Düzenle
                </b-button>
                <b-button variant="danger" class="btn-icon ml-1 mt-2" @click="taskDefinitionRemove(taskDefinition)">
                  <feather-icon icon="XIcon"/>
                  Çıkar
                </b-button>
              </div>
              <div v-if="taskDefinition.document && taskDefinition.document.startsWith('/')">
                <feather-icon :id="`row-${index}-prev`" icon="PaperclipIcon" size="20" @click="previewFile(taskDefinition.document)" class="mx-1 cursor-pointer"/>
              </div>
            </div>

            <div class="pl-2 pt-1">

              <div class="border-top border-bottom pl-1 pb-1 pt-1 mb-1 mt-1 d-flex">
                <feather-icon icon="ListIcon" size="19"/>
                <strong class="mb-0 ml-50">İş Adımları</strong>
              </div>

              <div v-for="(process, processIndex) in taskDefinition.processes" :key="processIndex" class="d-flex justify-content-between align-items-center border-top pl-1 pt-1 pb-1 bg-light-secondary" v-if="!process.isDeleted">
                <div class="d-flex justify-content-between align-items-center custom-class">
                  <b-avatar size="32" class="mr-50" :text="process.sortNumber.toString()" :variant="`light-primary`"/>
                  <div class="user-page-info">
                    <h6 class="mb-0">
                      {{ process.name }}
                    </h6>
                    <small class="text-muted">{{ process.description }}</small>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                  <div v-if="process.document && process.document.startsWith('/')">
                    <feather-icon :id="`row-${index}-prev`" icon="PaperclipIcon" size="20" @click="previewFile(process.document)" class="mx-1 cursor-pointer"/>
                  </div>
                  <b-button variant="warning" class="btn-icon" @click="taskDefinitionProcessEdit(taskDefinition, process)">
                    <feather-icon icon="EditIcon"/>
                    Düzenle
                  </b-button>
                  <b-button variant="danger" class="btn-icon ml-1" @click="taskDefinitionProcessRemove(taskDefinition, process)">
                    <feather-icon icon="XIcon"/>
                    Çıkar
                  </b-button>
                </div>
              </div>

            </div>

          </app-collapse-item>

        </app-collapse>

        <b-modal v-model="taskDefinitionModalShow" title="İş Tanımı Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="taskDefinitionHandleModal" size="lg">
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-form-group label="* İsim">
                <b-form-input trim placeholder="İsim" v-model="taskDefinitionInfo.name"/>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <b-form-group label="SLA Süresi (Saat)">
                <b-form-input trim placeholder="SLA Süresi" v-model="taskDefinitionInfo.slaTime"/>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6">
              <b-form-group label="* Görev Açılırken Gösterilsin">
                <v-select v-model="taskDefinitionInfo.showOnTask" :options="yesNoOptions" :reduce="val => val.value" :clearable="false"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <b-form-group label="Anahtar Kelimeler">
                <b-form-tags trim placeholder="Anahtar Kelimeler..." v-model="taskDefinitionInfo.tag"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="6">
              <b-form-group label="Delege Pozisyonlar">
                <v-select v-model="taskDefinitionInfo.positionIds" :options="positionOptions" :reduce="val => val.value" :clearable="false" multiple="multiple"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" lg="12">
              <b-form-group label="Döküman">
                <b-form-file id="taskDefinitionDocument" name="taskDefinitionDocument" placeholder="Dosya seçimi yapınız..." drop-placeholder="Dosyanızı sürükleyerek buraya bırakabilirsiniz..."/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" lg="12">
              <b-form-group label="Açıklama">
                <b-form-textarea v-model="taskDefinitionInfo.description" rows="2" placeholder="Açıklama..."/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" lg="12">
              <b-form-group label="Durum">
                <v-select v-model="taskDefinitionInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
              </b-form-group>
            </b-col>
          </b-row>

        </b-modal>

        <b-modal v-model="taskDefinitionProcessModalShow" title="Prosedür Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @ok="taskDefinitionProcessHandleModal" size="lg">
          <b-form-group label="* Sıra No">
            <b-form-input trim placeholder="Sıra No" v-model="taskDefinitionProcessInfo.sortNumber"/>
          </b-form-group>

          <b-form-group label="* İsim">
            <b-form-input trim placeholder="İsim" v-model="taskDefinitionProcessInfo.name"/>
          </b-form-group>

          <b-form-group label="Açıklama">
            <b-form-input trim placeholder="Açıklama" v-model="taskDefinitionProcessInfo.description"/>
          </b-form-group>

          <b-form-group label="Döküman">
            <b-form-file id="taskDefinitionProcessDocument" name="taskDefinitionProcessDocument" placeholder="Dosya seçimi yapınız..." drop-placeholder="Dosyanızı sürükleyerek buraya bırakabilirsiniz..."/>
          </b-form-group>

          <b-form-group label="Durum">
            <v-select v-model="taskDefinitionProcessInfo.isActive" :options="statusOptions" :reduce="val => val.value" :clearable="false"/>
          </b-form-group>
        </b-modal>

        <action-buttons :back-route="'definition-task-category-list'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BTabs, BTab, BFormTextarea, BAvatar, BFormCheckbox, BButton, BModal, BFormTags, BFormFile
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import {onUnmounted, ref} from '@vue/composition-api'
import {useToast} from 'vue-toastification/composition'
import {toastMessage} from "@core/utils/utils";
import router from '@/router'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email} from '@validations'
import {avatarText, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue";
import ActionButtons from "@/components/Form/ActionButtons.vue";
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/definition/taskcategory/store"
import positionModule from "@/views/organization/position/store"
import flatpickr from "vue-flatpickr-component/src/component"
import Vue from "vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    AppCollapseItem,
    AppCollapse,

    BFormFile,
    BFormTags,
    BModal,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BFormTextarea,
    BAvatar,

    flatpickr,

    ActionButtons,
    Overlay,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {},
  data() {
    return {
      required,
      email,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_POSITION_MODULE_NAME = 'store-position'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_POSITION_MODULE_NAME, positionModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_POSITION_MODULE_NAME)
      }
    })

    const toBase64 = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

    const toast = useToast()
    const busy = ref(false)
    const dataInfo = ref({
      id: 0,
      name: '',
      positionId: null,
      description: '',
      showOnTask: true,
      isActive: true,
      taskDefinitions: []
    })

    const onSubmit = () => {
      dataInfo.value.taskDefinitions.forEach((value, index) => {
        value.positions = []
        if (value.positionIds !== null && value.positionIds !== undefined) {
          value.positionIds.forEach((position, index) => {
            value.positions.push({'id': position, 'name': ''})
          });
        } else {
          value.positions = []
        }
      })

      busy.value = true
      store.dispatch('store/' + (router.currentRoute.params.id > 0 ? 'editItem' : 'addItem'), dataInfo.value).then(response => {
        toastMessage(toast, 'success', response.data.message)
        router.push({name: 'definition-task-category-list'})
      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const positionOptions = ref([])
    busy.value = true
    store.dispatch('store-position/fetchItems').then(response => {
      response.data.data.forEach((value, index) => {
        if (value.isActive) {
          positionOptions.value.push({label: value.name, value: value.id})
        }
      });
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    if (router.currentRoute.params.id > 0) {
      busy.value = true
      store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
        dataInfo.value = response.data.data

        dataInfo.value.taskDefinitions.forEach((value, index) => {
          value.positionIds = []
          if (value.positions !== null) {
            value.positions.forEach((position, index) => {
              value.positionIds.push(position.id)
            });
          } else {
            value.positionIds = []
          }
        })

      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
        if (error.response.status === 403) {
          router.push({name: 'definition-task-category-list'})
        }
      }).finally(message => {
        busy.value = false
      })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const taskDefinitionInfo = ref({
      id: null,
      name: '',
      description: '',
      slaTime: null,
      tag: null,
      isActive: true,
      showOnTask: true,
      isDeleted: false,
      processes: [],
      positionIds: null,
      document: null,
    })
    const taskDefinitionEditInfo = ref(null)
    const taskDefinitionModalShow = ref(false)

    const taskDefinitionNewModal = () => {
      taskDefinitionInfo.value = {
        id: null,
        name: '',
        description: '',
        slaTime: null,
        tag: null,
        isActive: true,
        showOnTask: true,
        isDeleted: false,
        processes: [],
        positionIds: null,
        document: null,
      }

      taskDefinitionModalShow.value = true
    }

    const taskDefinitionSubmitModal = async () => {
      let isAppendItem = true
      let documentFile = null;

      if (document.querySelector('#taskDefinitionDocument').files.length > 0) {
        const file = document.querySelector('#taskDefinitionDocument').files[0];
        documentFile = await toBase64(file)
      }

      dataInfo.value.taskDefinitions.forEach((value, index) => {
        if (value === taskDefinitionEditInfo.value) {
          isAppendItem = false
          value.id = taskDefinitionInfo.value.id
          value.name = taskDefinitionInfo.value.name
          value.description = taskDefinitionInfo.value.description
          value.slaTime = taskDefinitionInfo.value.slaTime
          value.tag = taskDefinitionInfo.value.tag
          value.isActive = taskDefinitionInfo.value.isActive
          value.showOnTask = taskDefinitionInfo.value.showOnTask
          value.isDeleted = taskDefinitionInfo.value.isDeleted
          value.processes = taskDefinitionInfo.value.processes
          value.positionIds = taskDefinitionInfo.value.positionIds
          value.document = documentFile
        }
      });

      if (isAppendItem) {
        dataInfo.value.taskDefinitions.push({
          id: taskDefinitionInfo.value.id,
          name: taskDefinitionInfo.value.name,
          description: taskDefinitionInfo.value.description,
          slaTime: taskDefinitionInfo.value.slaTime,
          tag: taskDefinitionInfo.value.tag,
          isActive: taskDefinitionInfo.value.isActive,
          showOnTask: taskDefinitionInfo.value.showOnTask,
          isDeleted: taskDefinitionInfo.value.isDeleted,
          processes: taskDefinitionInfo.value.processes,
          positionIds: taskDefinitionInfo.value.positionIds,
          document: documentFile
        })
      }

      taskDefinitionInfo.value = {
        id: null,
        name: '',
        description: '',
        slaTime: null,
        tag: null,
        isActive: true,
        showOnTask: true,
        isDeleted: false,
        processes: [],
        positionIds: null,
        document: null,
      }

      taskDefinitionModalShow.value = false
      taskDefinitionEditInfo.value = null
    }

    const taskDefinitionHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      if (taskDefinitionInfo.value.name === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'İsim zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }
      taskDefinitionSubmitModal()
    }

    const taskDefinitionRemove = (dataItem) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.taskDefinitions.forEach((value, index) => {
            if (value === dataItem) {
              value.isDeleted = true
            }
          });
        }
      })
    }

    const taskDefinitionEdit = (dataItem) => {
      taskDefinitionEditInfo.value = dataItem

      taskDefinitionInfo.value = {
        id: dataItem.id,
        name: dataItem.name,
        description: dataItem.description,
        slaTime: dataItem.slaTime,
        tag: dataItem.tag,
        isActive: dataItem.isActive,
        showOnTask: dataItem.showOnTask,
        isDeleted: dataItem.isDeleted,
        processes: dataItem.processes,
        positionIds: dataItem.positionIds,
        document: null,
      };

      taskDefinitionModalShow.value = true
    }

    //Processes
    const taskDefinition = ref(null)
    const taskDefinitionProcessModalShow = ref(false)
    const taskDefinitionProcessInfo = ref({
      id: null,
      sortNumber: 0,
      name: '',
      description: '',
      isActive: true,
      isDeleted: false,
      document: null,
    })
    const taskDefinitionProcessEditInfo = ref(null)

    const taskDefinitionProcessNewModal = (taskDefinitionData) => {
      taskDefinition.value = taskDefinitionData
      taskDefinitionProcessInfo.value = {
        id: null,
        sortNumber: taskDefinitionData.processes.length + 1,
        name: '',
        description: '',
        isActive: true,
        isDeleted: false,
        document: null,
      }
      taskDefinitionProcessModalShow.value = true
    }
    const taskDefinitionProcessSubmitModal = async () => {
      let documentFile = null;
      if (document.querySelector('#taskDefinitionProcessDocument').files.length > 0) {
        const file = document.querySelector('#taskDefinitionProcessDocument').files[0];
        documentFile = await toBase64(file)
      }

      dataInfo.value.taskDefinitions.forEach((value, index) => {
        if (value === taskDefinition.value) {
          if (taskDefinitionProcessEditInfo.value === null) {
            value.processes.push({
              id: taskDefinitionProcessInfo.value.id,
              sortNumber: taskDefinitionProcessInfo.value.sortNumber,
              name: taskDefinitionProcessInfo.value.name,
              description: taskDefinitionProcessInfo.value.description,
              isActive: taskDefinitionProcessInfo.value.isActive,
              isDeleted: taskDefinitionProcessInfo.value.isDeleted,
              document: documentFile
            })
          } else {
            value.processes.forEach((valueProcess, indexProcess) => {
              if (valueProcess === taskDefinitionProcessEditInfo.value) {
                valueProcess.id = taskDefinitionProcessInfo.value.id
                valueProcess.sortNumber = taskDefinitionProcessInfo.value.sortNumber
                valueProcess.name = taskDefinitionProcessInfo.value.name
                valueProcess.description = taskDefinitionProcessInfo.value.description
                valueProcess.isActive = taskDefinitionProcessInfo.value.isActive
                valueProcess.isDeleted = taskDefinitionProcessInfo.value.isDeleted
                valueProcess.document = documentFile
              }
            })
          }
        }
      });

      taskDefinitionProcessInfo.value = {
        id: null,
        sortNumber: 0,
        name: '',
        description: '',
        isActive: true,
        isDeleted: false,
        document: null,
      };

      taskDefinition.value = null
      taskDefinitionProcessModalShow.value = false
      taskDefinitionProcessEditInfo.value = null
    }
    const taskDefinitionProcessHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      taskDefinitionProcessSubmitModal()
    }
    const taskDefinitionProcessRemove = (taskDefinitionData, processData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Kayıt çıkarılacaktır!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Çıkar',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.taskDefinitions.forEach((value, index) => {
            if (value === taskDefinitionData) {
              value.processes.forEach((valueProcess, indexProcess) => {
                if (valueProcess === processData) {
                  valueProcess.isDeleted = true
                }
              })
            }
          })
        }
      })
    }
    const taskDefinitionProcessEdit = (taskDefinitionData, processData) => {
      taskDefinition.value = taskDefinitionData
      taskDefinitionProcessEditInfo.value = processData

      taskDefinitionProcessInfo.value.id = processData.id
      taskDefinitionProcessInfo.value.sortNumber = processData.sortNumber
      taskDefinitionProcessInfo.value.name = processData.name
      taskDefinitionProcessInfo.value.description = processData.description
      taskDefinitionProcessInfo.value.isActive = processData.isActive
      taskDefinitionProcessInfo.value.isDeleted = processData.isDeleted
      taskDefinitionProcessInfo.value.document = null

      taskDefinitionProcessModalShow.value = true
    }

    const previewFile = (filePath) => {
      window.open(
          getApiFile(filePath),
          '_blank' // <- This is what makes it open in a new window.
      );
    }

    return {
      busy,
      dataInfo,
      refFormObserver,
      positionOptions,

      statusOptions,
      yesNoOptions,

      taskDefinitionInfo,
      taskDefinitionEditInfo,
      taskDefinitionModalShow,

      taskDefinitionProcessInfo,
      taskDefinitionProcessEditInfo,
      taskDefinitionProcessModalShow,

      taskDefinitionNewModal,
      taskDefinitionSubmitModal,
      taskDefinitionHandleModal,
      taskDefinitionRemove,
      taskDefinitionEdit,

      taskDefinitionProcessNewModal,
      taskDefinitionProcessSubmitModal,
      taskDefinitionProcessHandleModal,
      taskDefinitionProcessRemove,
      taskDefinitionProcessEdit,

      onSubmit,
      getValidationState,
      resetForm,
      getApiFile,
      avatarText,
      previewFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-class {
  max-width: 80%;
}

</style>